import { AxiosPromise } from 'axios';
import LicenseInfoClass from '@/model/user/License/LicenseInfoClass';
import LicenseID from '@/model/user/License/LicenseID';
import LicenseMasterInfo from '@/model/user/License/LicenseMasterInfo';
import axios from '../AxiosBase';

export default {
  findAll(userid:any):AxiosPromise<any> {
    return axios.get(`license/all/${userid}`);
  },
  save(licenseInfoClass:LicenseInfoClass):AxiosPromise<any> {
    return axios.post('license/save', licenseInfoClass);
  },
  update(licenseInfoClass:LicenseInfoClass, licenseId:LicenseID):AxiosPromise<any> {
    return axios.post('license/update', { licenseInfo: licenseInfoClass, oldLicenseId: licenseId });
  },
  delete(licenseInfoClass:LicenseInfoClass):AxiosPromise<any> {
    return axios.post('license/delete', licenseInfoClass);
  },
  getMaster():AxiosPromise<LicenseMasterInfo[]> {
    return axios.get('license/master');
  }
};
