import FileId from '@/model/type/FileId';
import FileName from '@/model/type/FileName';
import TypeDateTime from '@/model/type/TypeDateTime';
import TypeJpDate from '@/model/type/TypeJpDate';
import Name from '../history/Name';
import UserID from '../UserID';
import File from './File';
import LicenseID from './LicenseID';
import LicenseNumber from './LicenseNumber';

export default class LicenseInfoClass {
  userId: UserID;

  licenseId: LicenseID;

  name: Name;

  number: LicenseNumber;

  date: TypeJpDate;

  licensefile: FileId;

  licensefileName: FileName;

  createDate: TypeDateTime;

  updateDate: TypeDateTime;

  /**
   * 登録画面でのv-for制御用項目
   */
  seq:number = 0;

  constructor(
    userId?: UserID,
    licenseId?: LicenseID,
    name?: Name,
    number?: LicenseNumber,
    date?: TypeJpDate,
    licensefile?: FileId,
    licensefileName?: FileName,
    createDate?:TypeDateTime,
    updateDate?:TypeDateTime
  ) {
    this.userId = (userId === undefined ? new UserID() : userId);
    this.licenseId = (licenseId === undefined ? new LicenseID() : licenseId);
    this.name = (name === undefined ? new Name() : name);
    this.number = (number === undefined ? new LicenseNumber() : number);
    this.date = (date === undefined ? new TypeJpDate() : date);
    this.licensefile = (licensefile === undefined ? new FileId() : licensefile);
    this.licensefileName = (licensefileName === undefined ? new FileName() : licensefileName);
    this.createDate = (createDate === undefined ? new TypeDateTime() : createDate);
    this.updateDate = (updateDate === undefined ? new TypeDateTime() : updateDate);
  }

  public static clioneList(source:LicenseInfoClass[]):LicenseInfoClass[] {
    const result : LicenseInfoClass[] = [];
    source.forEach((s) => {
      result.push(LicenseInfoClass.clone(s));
    });
    return result;
  }

  public static clone(source: LicenseInfoClass): LicenseInfoClass {
    const result = new LicenseInfoClass();
    result.userId = UserID.clone(source.userId);
    result.licenseId = LicenseID.clone(source.licenseId);
    result.name = Name.clone(source.name);
    result.number = LicenseNumber.clone(source.number);
    result.date = TypeJpDate.clone(source.date);
    result.licensefile = FileId.clone(source.licensefile);
    result.licensefileName = FileName.clone(source.licensefileName);
    result.createDate = source.createDate;
    result.updateDate = source.updateDate;
    return result;
  }

  public static create() {
    const result : LicenseInfoClass[] = [];
    return result;
  }
}
