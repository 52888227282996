
import { Vue, Component, Prop } from 'vue-property-decorator';
import LicenseInfoClass from '@/model/user/License/LicenseInfoClass';
import LicenseEdit from '@/components/User/License/LicenseEdit.vue';
import LicenseApi from '@/module/api/LicenseApi';
import UserApi from '@/module/api/UserApi';
import LicenseID from '@/model/user/License/LicenseID';

@Component({
  components: {
    LicenseEdit
  }
})
export default class LicenseInfo extends Vue {
  edit:boolean = false;
  licenseId!:LicenseID;
  url:string = ''
  @Prop()license!:LicenseInfoClass;

  mounted():void {
    this.edit = false;
    this.getfileurl();
  }
  getfileurl():void{
    if (this.license.licensefile.value === '') return;
    if (this.license.licensefile === undefined) return;
    UserApi.getURL(this.license.licensefile.value).then((res) => {
      this.url = res.data;
    });
  }
  update():void{
    this.edit = true;
  }
  deleteLicense():void{
    LicenseApi.delete(this.license);
    this.$emit('deletelicense', this.license);
  }
  cancel(updatelicense:LicenseInfoClass):void{
    this.edit = false;
    if (updatelicense) {
      this.license = updatelicense;
    }
  }
}
