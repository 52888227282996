
import { Vue, Component, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import LicenseInfoClass from '@/model/user/License/LicenseInfoClass';
import LicenseApi from '@/module/api/LicenseApi';
import FileUploadApi from '@/module/api/FileUploadApi';
import UserApi from '@/module/api/UserApi';
import UserID from '@/model/user/UserID';
import FileUpload from '@/components/Parts/FileUpload.vue';
import InputJpDate from '@/components/Parts/InputJpDate.vue';
import { ErrorResponseModel } from '@/model/StaffManager';
import UploadFileContainer from '@/model/type/UploadFileContainer';
import LicenseMasterInfo from '@/model/user/License/LicenseMasterInfo';
import UserUpdateItemInfo from '@/model/user/UserUpdateItemInfo';
import UpdateFields from '@/model/user/UpdateFields';

@Component({
  components: {
    InputJpDate,
    FileUpload
  }
})
export default class LicenseEdit extends Vue {
  openEdit :boolean = true;
  @Prop() edit !:boolean;
  @Prop()originLicense!:LicenseInfoClass;
  @Prop()userId!:UserID;
  @Prop({ default: -1 })idx!:number;
  license:LicenseInfoClass = new LicenseInfoClass();
  uploadfile:UploadFileContainer = new UploadFileContainer();
  data = new FormData();
  newfileName = '';
  licenseMasterList:LicenseMasterInfo[]=[];
  userUpdateItemList:UserUpdateItemInfo[]=[];

  async mounted():Promise<void> {
    const res = await LicenseApi.getMaster();
    this.licenseMasterList = res.data;
    if (this.originLicense !== undefined) {
      this.license = LicenseInfoClass.clone(this.originLicense);
    } else {
      this.license = new LicenseInfoClass();
    }
  }
  /** 新規登録キャンセル */
  cancel():void{
    this.openEdit = false;
  }

  /** 編集キャンセル */
  cancelEdit(updateLicense:LicenseInfoClass):void{
    this.$emit('cancel', updateLicense);
  }

  /** 編集登録 */
  async editsave():Promise<void> {
    if (this.checkData()) {
      this.$root.$emit('event-show-snackbar-infinity', '必須項目に誤りがあります。');
      return;
    }
    if (this.data.has('upfile')) {
      this.data.append('fileId', this.license.licensefile.value);
      const fileId = await FileUploadApi.upload(this.data);
      this.license.licensefile.value = fileId.data.value;
      this.license.licensefileName.value = this.newfileName;
    }
    LicenseApi.update(this.license, this.originLicense.licenseId).then((res) => {
      const userUpdateItem:UserUpdateItemInfo = new UserUpdateItemInfo(this.license.userId, new UpdateFields('license'));
      this.userUpdateItemList.push(userUpdateItem);
      UserApi.saveUpdateItem(this.userUpdateItemList);
      const updateLicense:LicenseInfoClass = LicenseInfoClass.clone(res.data);
      this.$emit('cancel', updateLicense);
    });
  }

  licensefileUpload(file:File, fileName:string):void{
    this.data.append('upfile', file);
    this.newfileName = fileName;
  }

  /** 新規登録 */
  async save():Promise<void> {
    if (this.checkData()) {
      this.$root.$emit('event-show-snackbar-infinity', '必須項目に誤りがあります。');
      return;
    }
    /** ユーザーIDをここで入れてやる */
    this.license.userId = this.userId;
    if (this.data.has('upfile')) {
      this.data.append('fileId', this.license.licensefile.value);
      const fileId = await FileUploadApi.upload(this.data);
      this.license.licensefile.value = fileId.data.value;
      this.license.licensefileName.value = this.newfileName;
    }
    LicenseApi.save(this.license).then((res) => {
      const addlicense:LicenseInfoClass = res.data;
      const userUpdateItem:UserUpdateItemInfo = new UserUpdateItemInfo(this.license.userId, new UpdateFields('license'));
      this.userUpdateItemList.push(userUpdateItem);
      UserApi.saveUpdateItem(this.userUpdateItemList);

      this.$emit('addLicense', addlicense, this.idx);
      this.openEdit = false;
    }).catch((error:AxiosError<ErrorResponseModel>) => {
      this.openEdit = false;
      if (error.response?.data.code === 'DuplicateKey') {
        this.$root.$emit('event-show-snackbar-infinity', error.response?.data.detail);
        return;
      }
      this.$root.$emit('event-show-snackbar-infinity', '予期しないエラーが発生しました。');
    });
  }
  checkData():boolean {
    if (this.license.licenseId.value === 0) return true;
    if (this.license.number.value === '') return true;
    if (!this.license.date.isValid) return true;
    return false;
  }
}
