export default class LicenseID {
  value: number;

  constructor(value?: number) {
    this.value = (value === undefined ? 0 : value);
  }
  public static clone(source: LicenseID): LicenseID {
    return new LicenseID(source.value);
  }
}
