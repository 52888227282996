
import { Vue, Component } from 'vue-property-decorator';
import LicenseInfo from '@/components/User/License/LicenseInfo.vue';
import LicenseEdit from '@/components/User/License/LicenseEdit.vue';
import LicenseApi from '@/module/api/LicenseApi';
import LicenseInfoClass from '@/model/user/License/LicenseInfoClass';
import UserID from '@/model/user/UserID';
import BackButton from '@/components/Parts/BackButton.vue';

@Component({
  components: {
    LicenseInfo,
    LicenseEdit,
    BackButton
  }
})
export default class LicenseRegister extends Vue {
  userId!:UserID;
  license:LicenseInfoClass[] = [];
  appendedLicense:LicenseInfoClass[] = [];
  sequence: number = 0;
  mounted():void{
    LicenseApi.findAll(this.$route.params.userId).then((res) => {
      this.license = res.data;
      /* eslint-disable no-plusplus */
      // eslint-disable-next-line no-param-reassign
      this.license.forEach((l) => { l.seq = this.sequence++; });
      this.userId = new UserID(this.$route.params.userId);
    });
  }
  add(addlicense:LicenseInfoClass, idx:number):void{
    // eslint-disable-next-line no-param-reassign
    addlicense.seq = this.sequence++;
    this.license.push(addlicense);
    this.appendedLicense.splice(idx);
  }
  licenseAdd(): void {
    const license = new LicenseInfoClass();
    license.seq = this.sequence++;
    this.appendedLicense.push(license);
  }
  deletelicense(deletelicense:LicenseInfoClass):void{
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.license.length; i++) {
      if (this.license[i].licenseId.value === deletelicense.licenseId.value) {
        this.license.splice(i, 1);
        break;
      }
    }
  }
}
